<template>
  <div class="main">
    <heads></heads>
    <banners></banners>
    <div class="content">
      <div class="xtjs">
        <div class="xtjs-title">关于我们</div>
        <div class="xtjs-con">
          <p>
            我们知道快速发表研究对科研人员来说是多么重要，这样他们就可以回到最重要的事情上：学术研究。我们的服务正是以这一目标为基础精心设计的。
          </p>
          <p>
            论文智能投稿平台致力于帮助科研人员提高和润色他们的稿件，加快投稿过程，并提高发表的可能性。无论您选择哪一个期刊，我们都可提供来自学科专家编辑的高质量服务，帮助您准备发表稿件。
          </p>
          <p>
           当您提交稿件时，您的文稿将被匹配给两位文章专属研究领域的资深编辑。我们拥有3,000多名具有博士或硕士学历的编辑专家。许多编辑在一流大学或研究所从事研究工作，并在SCI期刊上发表研究成果。这些英语编辑具备平均19.4年的丰富的润色经验。为了保护您的研究成果，我们和每个签约编辑都签署了保密协议。
          </p>
          <p>
            无论您是从事什么领域的研究，论文智能投稿平台都将帮助您以清晰、可发表的英文在国际上展示您的论文。论文智能投稿平台还可提供图形、图表和数字，以及视频、信息图表和海报服务，以帮助您最大程度地提高已发表研究的影响力。
          </p>
          
        </div>
      </div>
    </div>

    <footers></footers>
  </div>
</template>

<style scoped>
.main {
  background: #fbfcff;
}
.content {
  width: 1200px;
  height: auto;
  padding: 40px 0;
  margin: auto;
}
.xtjs-title{
  font-size: 24px;
  text-align: center;
}
.xtjs-con{
  padding: 10px 0;
}
.xtjs-con p{
  text-indent: 32px;
  text-align: justify;
}
</style>
